import { useToken } from '@chakra-ui/react';

import { App } from '@cccom/config/app-type';

import {
  useAppsList,
  useGetConfig,
  useHelpCenter,
  useTimeZone,
  useWizardSettings,
} from './api';
import { ConfigSettings, ContentMaxWidth } from './types';

type UseSettingsProps = {
  maxWidth?: ContentMaxWidth;
};

type SettingsState = {
  timezone: string;
  helpCenterUrl: string;
  appList: App[];
  contentMaxWidth: string;
  wizardSettings: { skip: boolean };
  configSettings: ConfigSettings;
};

export function useSettings(props?: UseSettingsProps): SettingsState {
  const [contentMaxWidth] = useToken('breakpoints', [props?.maxWidth ?? 'xl']);
  const { data: timezone } = useTimeZone();
  const { data: helpCenter } = useHelpCenter();
  const { data: appList } = useAppsList();
  const { data: wizardSettings } = useWizardSettings();
  const { data: configSettings } = useGetConfig();

  const settings: SettingsState = {
    timezone: timezone?.timezone ?? 'UTC',
    helpCenterUrl: helpCenter?.url ?? '',
    appList: appList ?? [],
    contentMaxWidth,
    wizardSettings: wizardSettings ?? { skip: false },
    configSettings: configSettings ?? { enableSOPId: false },
  };

  return settings;
}
